import { createMemoryHistory, createRouter } from 'vue-router'
// import store from '@/store'
import MainScreen from "@/components/MainScreen";

const routes = [
    {
        path: '/',
        component: MainScreen,
    },
]

const router = createRouter({
    history: createMemoryHistory(),
    routes,
})

// router.beforeEach((to, from, next) => {
//     if (to.matched.some(route => route.meta.guest)) {
//         return next();
//     }
//
//     if (store.state.currentClientId) {
//         return next();
//     }
//
//     return next({ name: 'login' });
// });

export default router;