<template>
  <div class="container text-secondary">
    <div class="row mb-4">
      <div class="col">
        <img src="@/assets/pencil.svg" alt="" class="img-fluid mt-0 d-inline-block opacity-75" style="height: 40px;"/>
        <div class="h2 mt-2 d-inline-block">Scoreblokje</div>
      </div>
    </div>

    <hr class="mb-4">

    <div class="row mb-3">
      <div class="col">
        Binnenkort meer
      </div>
    </div>

<!--    <div class="row mb-3">-->
<!--      <div class="col">-->
<!--        <img src="@/assets/cards-100.png" alt="cards" class="img-fluid opacity-75"/>-->
<!--        Kaarten-->
<!--      </div>-->
<!--    </div>-->

<!--    <div class="row mb-3">-->
<!--      <div class="col">-->
<!--        <img src="@/assets/dice-100.png" alt="dice" class="img-fluid opacity-75"/>-->
<!--        Dobbelen-->
<!--      </div>-->
<!--    </div>-->
  </div>

</template>

<script>
export default {
  name: "MainScreen"
}
</script>

<style scoped>

</style>